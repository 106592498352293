

















import { Component, Prop, Vue } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'

interface Option {
  label: string
  value: string
}

@Component({
  components: {
    TextInput,
    DropdownSelect,
  },
})
export default class CODSearchInput extends Vue {
  @Prop({ type: String }) value!: string
  @Prop({ type: Object }) searchByValue!: Option
  @Prop({ type: Array }) options!: Option[]
  @Prop({ type: String }) placeholder!: string


  get search(): string {
    return this.value
  }

  set search(val: string) {
    this.$emit('input', val)
  }

  get searchBy(): Option {
    return this.searchByValue
  }

  set searchBy(val: Option) {
    if (!val) {
      this.$emit('type', this.options[0])
    } else {
      this.$emit('type', val)
    }
  }

  get filteredOptions(): Option[] {
    return this.options.filter(
      option => option.value !== this.searchByValue.value
    )
  }
}
