


























































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import CODSearchInput from '../components/CODSearchInput/index.vue'
import CODFilter from '../components/CODFilter/index.vue'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import DropdownMultiSelect from '../components/DropdownMultiSelect/index.vue'
import {
  IHeaderCell,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/cod'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import {
  CODPaymmentSortFields,
  CODPaymmentSortType,
  EnumCODPaymentStatus,
  EnumSearchBy,
  codTypeOptions,
  datepickerPresets,
  paymentTypeOptions,
  searchByOptions,
  statusOptions,
} from '@/app/infrastructures/misc/Constants/cod'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import Badge from '@/app/ui/components/Badge/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import controller from '@/app/ui/controllers/CODController'
import { CODPayment } from '@/domain/entities/COD'

interface IOption {
  label: string
  value: string | number
}

interface IParameters {
  page: number
  perPage: number
  search: string
  searchBy: IOption
  codType?: string[]
  paymentType?: string[]
  status?: string[]
  dateRange: {
    start: Date | null
    end: Date | null
  }
  syncSummary: boolean
}

@Component({
  components: {
    Button,
    CODSearchInput,
    CODFilter,
    DateTimePickerV2,
    DropdownMultiSelect,
    DataTableV2,
    EmptyState,
    PaginationNav,
    Badge,
    LoadingOverlay,
    AscendingIcon,
  },
})
export default class CODPaymentList extends Vue {
  controller = controller

  enumSortFields = CODPaymmentSortFields
  enumSelectedSort = CODPaymmentSortType

  selectedSort: CODPaymmentSortType | null = null

  perPageOptions: IOption[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  headers = [
    this.headerCellMapper('No', '60px'),
    this.headerCellMapper('Tanggal POD', '160px'),
    this.headerCellMapper('STT', '170px'),
    this.headerCellMapper('Nominal COD', '136px'),
    this.headerCellMapper('Kurir', '230px'),
    this.headerCellMapper('Tipe Pembayaran', '167px'),
    this.headerCellMapper('Tipe COD', '106px'),
    this.headerCellMapper('Status', '157px'),
    this.headerCellMapper('Atur', '180px'),
  ]

  searchByOptions = searchByOptions
  codTypeOptions = codTypeOptions
  paymentTypeOptions = paymentTypeOptions
  statusOptions = statusOptions
  datepickerPresets = datepickerPresets

  parameters: IParameters = {
    page: 1,
    perPage: <number>this.perPageOptions[0].value,
    search: '',
    searchBy: this.searchByOptions[0],
    codType: [],
    paymentType: [],
    status: [],
    dateRange: {
      start: null,
      end: null,
    },
    syncSummary: true,
  }

  listData: ITableCell[][] = []

  created(): void {
    this.fetchList(true)
    this.parameters.syncSummary = false
  }

  get searchInputPlaceholder(): string {
    const result = 'Cari '

    if (this.parameters.searchBy.value === EnumSearchBy.COURIER) {
      return `${result}ID/Nama Kurir atau No. Hp`
    }

    return `${result}Dengan Nomor STT`
  }

  get filterCounter(): number {
    return (
      <number>this.parameters.codType?.length +
      <number>this.parameters.paymentType?.length +
      <number>this.parameters.status?.length
    )
  }

  get params(): Record<string, string | number | boolean> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      searchBy: this.parameters.searchBy.value,
      searchKey: this.parameters.search,
      codType: <string>(
        this.parameters.codType
          ?.map(
            type =>
              this.codTypeOptions.find(codType => codType.label === type)?.value
          )
          .join(',')
      ),
      paymentType: <string>(
        this.parameters.paymentType
          ?.map(
            type =>
              this.paymentTypeOptions.find(
                paymentType => paymentType.label === type
              )?.value
          )
          .join(',')
      ),
      paymentStatus: <string>(
        this.parameters.status
          ?.map(
            type =>
              this.statusOptions.find(status => status.label === type)?.value
          )
          .join(',')
      ),
      columnSort: this.selectedSort ? this.selectedSort.split('-')[0] : '',
      sortDirection: this.selectedSort
        ? this.selectedSort.split('-')[1].toUpperCase()
        : '',
      syncSummary: this.parameters.syncSummary,
      endDate: this.parameters.dateRange.end
        ? Utils.formatDateWithIDLocale(
            <string>this.parameters.dateRange.end?.toDateString(),
            'YYYY-MM-DD'
          )
        : '',
      startDate: this.parameters.dateRange.start
        ? Utils.formatDateWithIDLocale(
            <string>this.parameters.dateRange.start?.toDateString(),
            'YYYY-MM-DD'
          )
        : '',
    }
  }

  private formatPaymentType(type: string): string {
    if (type === 'CASH') {
      return 'Cash (Tunai)'
    } else {
      return 'QRIS (Non-Tunai)'
    }
  }

  public formatStatus(status: string): { type: string; text: string } {
    switch (status) {
      case EnumCODPaymentStatus.WAITING:
        return {
          text: 'Menunggu',
          type: 'warning',
        }
      case EnumCODPaymentStatus.VALID:
        return {
          text: 'Sesuai',
          type: 'success',
        }

      default:
        return {
          text: 'Tidak Sesuai',
          type: 'error-1',
        }
    }
  }

  public fetchList(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.getAllCODPayment(this.params)
  }

  public exportToExcel(): void {
    const filename = `COD_LIST-${Utils.formatDateWithIDLocale(
      new Date().toISOString(),
      'YYYYMMDDHHmmss'
    )}`
    this.controller.exportCODPayments({
      ...this.params,
      fileName: filename,
    })
  }

  public onSearch = Utils.debounce(() => {
    this.fetchList(true)
  }, 400)

  public onChangePerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchList(true)
  }

  public onFilterReset(): void {
    this.parameters.codType = []
    this.parameters.paymentType = []
    this.parameters.status = []

    this.fetchList(true)
  }

  public onTableSort(sortType: CODPaymmentSortFields): void {
    switch (sortType) {
      case CODPaymmentSortFields.POD_DATE:
        if (this.selectedSort === CODPaymmentSortType.POD_DATE_ASC) {
          this.selectedSort = CODPaymmentSortType.POD_DATE_DESC
        } else if (this.selectedSort === CODPaymmentSortType.POD_DATE_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = CODPaymmentSortType.POD_DATE_ASC
        }
        break

      case CODPaymmentSortFields.COD_AMOUNT:
        if (this.selectedSort === CODPaymmentSortType.COD_AMOUNT_ASC) {
          this.selectedSort = CODPaymmentSortType.COD_AMOUNT_DESC
        } else if (this.selectedSort === CODPaymmentSortType.COD_AMOUNT_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = CODPaymmentSortType.COD_AMOUNT_ASC
        }
        break

      case CODPaymmentSortFields.COURIER:
        if (this.selectedSort === CODPaymmentSortType.COURIER_ASC) {
          this.selectedSort = CODPaymmentSortType.COURIER_DESC
        } else if (this.selectedSort === CODPaymmentSortType.COURIER_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = CODPaymmentSortType.COURIER_ASC
        }
        break
    }

    this.fetchList(true)
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('controller.codPaymentData')
  onCODPaymentDataChanged(data: CODPayment[]): void {
    this.listData = data.map((cod, index) => [
      this.tableCellMapper(
        index + 1 + this.parameters.perPage * (this.parameters.page - 1),
        '60px'
      ),
      this.tableCellMapper(
        [
          Utils.formatDateWithIDLocale(<string>cod.podDate, 'dddd, DD MMM'),
          Utils.formatDateWithIDLocale(<string>cod.podDate, 'YYYY, HH:mm'),
        ],
        '160px'
      ),
      this.tableCellMapper(<string>cod.packageId, '170px'),
      this.tableCellMapper(
        `Rp${Utils.currencyDigit(<number>cod.codAmount)}`,
        '136px'
      ),
      this.tableCellMapper(
        `[${cod.courierData?.courierId}] ${cod.courierData?.fullName} (${cod.courierData?.courierType}) ${cod.courierData?.phoneNumber}`,
        '230px'
      ),
      this.tableCellMapper(
        this.formatPaymentType(<string>cod.paymentMethod),
        '167px'
      ),
      this.tableCellMapper(<string>cod.codType, '106px'),
      this.tableCellMapper(<string>cod.statusTxt, '157px'),
      this.tableCellMapper(<string>cod.packageId, '180px'),
    ])
  }
}
