



































































import { Component, Prop, Vue } from 'vue-property-decorator'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import CloseLine from '@/app/ui/assets/close_line.vue'
import CheckedIcon from '@/app/ui/assets/check_line.vue'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import CheckBox from '../CheckBox/index.vue'
import DropdownMultiSelect from '../DropdownMultiSelect/index.vue'

@Component({
  name: 'CODFilter',
  components: {
    CaretDownIcon,
    CloseLine,
    CheckedIcon,
    ExpandIcon,
    CheckBox,
    DropdownMultiSelect,
  },
})
export default class CODFilter extends Vue {
  @Prop({ type: Number, default: 0 }) counter!: number
  isDropdownVisible = false

  private onClickField(): void {
    this.isDropdownVisible = !this.isDropdownVisible
  }

  private onClickOutside(): void {
    this.isDropdownVisible = false
  }
}
